<template>
	<div
		id="investorModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Investor</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addInvestor">
						<template v-if="getErrorLog.message">
							<div class="invalid-feedback alert alert-danger text-center" role="alert">
								{{ getErrorLog.message }}
							</div>
						</template>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="firstname">First Name</label>
								<input
									:class="[{ 'is-invalid': errors.firstname }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.firstname"
									name="firstname"
									value=""
									type="text"
									class="form-control"
									id="firstname"
									placeholder="First Name"
								/>
								<span class="modal__form__icon">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Person Icon</title>
										<path fill="none" d="M0 0h24v24H0z" />
										<path fill="none" d="M4 4h16v16H4z" />
										<path
											d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
										2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
											fill="#828282"
										/>
									</svg>
								</span>
								<div class="invalid-feedback">
									{{ errors.firstname }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="lastname">Last Name</label>
								<input
									:class="[{ 'is-invalid': errors.lastname }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.lastname"
									value=""
									name="lastname"
									type="text"
									class="form-control"
									id="lastname"
									placeholder="Last Name"
								/>
								<span class="modal__form__icon">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Person Icon</title>
										<path fill="none" d="M0 0h24v24H0z" />
										<path fill="none" d="M4 4h16v16H4z" />
										<path
											d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
										2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
											fill="#828282"
										/>
									</svg>
								</span>
								<div class="invalid-feedback">
									{{ errors.lastname }}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="phone">Phone Number</label>
								<input
									:class="[{ 'is-invalid': errors.phone }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.phone"
									name="phone"
									value=""
									type="tel"
									class="form-control"
									id="phone"
									placeholder="Phone Number"
								/>
								<span class="modal__form__icon">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Phone Icon</title>
										<path fill="none" d="M0 0h24v24H0z" />
										<path
											d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 
											1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 
											0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 
											3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
											fill="#828282"
										/>
									</svg>
								</span>
								<div class="invalid-feedback">
									{{ errors.phone }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="email">Email Address</label>
								<input
									:class="[{ 'is-invalid': errors.email }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.email"
									name="email"
									value=""
									type="email"
									class="form-control"
									id="email"
									placeholder="Email Address"
								/>
								<span class="modal__form__icon">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Mail Icon</title>
										<path fill="none" d="M0 0h24v24H0z" />
										<path fill="none" d="M2 4h20v16H2z" />
										<path
											d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 
										2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
											fill="#828282"
										/>
									</svg>
								</span>
								<div class="invalid-feedback">
									{{ errors.email }}
								</div>
							</div>
						</div>
						<div class="form-group">
							<label class="modal__label" for="password">Password</label>
							<input
								:class="[{ 'is-invalid': errors.password }]"
								@keydown="clearErrors($event.target.name)"
								v-model="itemData.password"
								name="password"
								value=""
								type="text"
								class="form-control"
								id="password"
								placeholder="********"
							/>
							<span class="modal__form__icon">
								<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Person Icon</title>
									<path fill="none" d="M0 0h24v24H0z" />
									<path fill="none" d="M4 4h16v16H4z" />
									<path
										d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
									2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
										fill="#828282"
									/>
								</svg>
							</span>
							<div class="invalid-feedback">
								{{ errors.password }}
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addInvestor"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addInvestor"
					>
						Add Investor
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import auth from '../../utils/validations/auth';

export default {
	name: 'AddInvestor',
	mixins: [form],
	data() {
		return {
			loading: false,
			itemData: {},
			errors: {}
		};
	},
	computed: {
		...mapGetters(['getStatus', 'getErrorLog'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
			}
		}
	},
	methods: {
		...mapActions(['createInvestor']),
		addInvestor() {
			this.validate(this.itemData, auth.register);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			this.loading = true;
			this.createInvestor(this.itemData);
			this.loading = false;
			return true;
		}
	}
};
</script>
