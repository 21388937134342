<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="contacts__img">
					<img v-if="investor.avatar" :src="investor.avatar" alt="Profile picture" />
					<img v-else src="../../assets/img/profile.png" alt="Profile picture" />
				</div>
				<div class="info__primary">
					<p class="info__name">{{ fullName }}</p>
					<p class="info__email">{{ investor.email }}</p>
				</div>
			</div>
			<p>
				<span :class="isActive">
					{{ investor.active === 1 ? 'Active' : 'Unverified' }}
				</span>
			</p>
			<p>
				<span :class="kyc">
					{{ investor.is_kyc_completed === 1 ? 'Completed' : 'Pending' }}
				</span>
			</p>
			<p>{{ investor.phone }}</p>
			<p class="info__address">{{ investor.email }}</p>
            <p>{{ investor.created_at }}</p>
			<p>
				<router-link
					:to="{ name: 'investor-profile', params: { id: investor.id } }"
					class="btn btn__primary"
					id="viewProfile"
				>
					View Profile
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorItem',
	props: {
		investorData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investor: this.investorData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		fullName() {
			return `${this.investor.firstname} ${this.investor.lastname}`;
		},
		isActive() {
			return this.investor.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		},
		kyc() {
			const isInvestorComplete = this.investor.is_kyc_completed === 1;
			return isInvestorComplete ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 1.5fr 0.5fr 0.5fr 0.6fr 1fr 0.4fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
